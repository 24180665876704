import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    Show,
    SimpleShowLayout,
    ReferenceField,
    ReferenceManyField,
    SingleFieldList,
    ChipField, Filter, SearchInput, DateTimeInput
} from 'react-admin';
import AmountField from "../fields/AmountField";
import {OperatorReferenceField} from "./operators";
import {CustomerReferenceField} from "./customers";
import {PayoutReferenceField} from "./payouts";
import {TicketDataGrid} from "./tickets";
import {PaymentBatchReferenceField} from "./paymentbatches";

const PaymentFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
        <DateTimeInput source="createdAtFrom" />
        <DateTimeInput source="createdAtTo" />
    </Filter>
);

export const PaymentDataGrid = props =>
    <Datagrid rowClick="show" {...props}>
        <TextField source="id"/>
        <DateField source="createdAt" showTime/>
        <TextField source={"status"}/>
        <TextField source={"error"}/>
        <AmountField source="amount"/>
        <PaymentBatchReferenceField source={'batchId'} label={'Payment Batch'} addLabel={true}/>
        <OperatorReferenceField source={"operatorId"} label={'Operator'}/>
        <CustomerReferenceField source={"customerId"} label={'Customer'} addLabel={true}/>
    </Datagrid>;


export const PaymentList = props => (
    <List {...props} filters={<PaymentFilter/>} sort={{field: 'createdAt', order: 'DESC'}}>
        <PaymentDataGrid/>
    </List>
);

export const PaymentShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id"/>
            <DateField source="createdAt" showTime/>
            <TextField source={"status"}/>
            <TextField source={"error"}/>
            <AmountField source="amount"/>
            <PaymentBatchReferenceField source={'batchId'} label={'Payment Batch'} addLabel={true}/>
            <OperatorReferenceField source={"operatorId"} label={'Operator'}/>
            <CustomerReferenceField source={"customerId"} label={'Customer'} addLabel={true}/>
        </SimpleShowLayout>
    </Show>
);

const IdReferenceField = ({record}) =>
    <ChipField source={"id"} record={{id: `${record.id || ""} (${record.status})`}}/>;

export const PaymentReferenceField = props =>
    <ReferenceField source="paymentId" reference="payments" link="show" {...props}>
        <IdReferenceField/>
    </ReferenceField>;

const PaymentFieldList = props =>
    <SingleFieldList {...props} basePath={"/payments"} linkType={"show"}>
        <IdReferenceField/>
    </SingleFieldList>;

export const PaymentReferenceManyField = props =>
    <ReferenceManyField label="Payment" reference="payments" target="payoutId" linkType={"show"} {...props}>
        <PaymentFieldList/>
    </ReferenceManyField>;
