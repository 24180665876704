// in src/comments/ApproveButton.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { showNotification as showNotificationAction, refreshView as refreshViewAction } from 'react-admin';
import fetchApi from "../fetchApi";
class ClaimToPayoutButton extends Component {
    constructor(props) {
        super(props);

        this.state = {submitting: false}
    }
    handleClick = () => {
        const { record, showNotification, refreshView } = this.props;

        this.setState({submitting: true})
        fetchApi(record._links.claimToPayout.href, {
            method: 'POST'
        }).then(() => {
            showNotification('Ticket claimed', 'info');

            refreshView();
            this.setState({submitting: false})
        }).catch((e) => {
            showNotification('Error: ticket not claimed', 'error');
            this.setState({submitting: false})
        });
    };

    render() {
        const {record} = this.props;
        const {submitting} = this.state;

        return <div>
                <Button variant={'contained'} color={'primary'} disabled={submitting || !record._links.claimToPayout} onClick={this.handleClick}>Claim to payout</Button>
                <span style={{visibility: !submitting ? 'hidden' : 'visible'}}><CircularProgress/></span>
            </div>;
    }
}

ClaimToPayoutButton.propTypes = {
    record: PropTypes.object,
    showNotification: PropTypes.func,
    refreshView: PropTypes.func,
};

export default connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
})(ClaimToPayoutButton)