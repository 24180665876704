// in src/Menu.js
import React, { createElement } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, getResources, useTranslate } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import inflection from 'inflection';
import NestedMenu from "./NestedMenu";
import { useLocation } from 'react-router-dom';
import find from 'lodash/find';

const translatedResourceName = (resource, translate) =>
    translate(`resources.${resource.name}.name`, {
        smart_count: 2,
        _:
            resource.options && resource.options.label
                ? translate(resource.options.label, {
                    smart_count: 2,
                    _: resource.options.label,
                })
                : inflection.humanize(inflection.pluralize(resource.name)),
    });



const Menu = ({ onMenuClick, logout, ...rest }) => {
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);
    const translate = useTranslate();
    const location = useLocation();

    const MenuResource = ({resource, resourceName}) => {
        if (!resource) {
            resource = resources.filter(r => r.name === resourceName)[0]
        }
        if (!resource) {
            return null
        }
        return <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={translatedResourceName(
                resource,
                translate
            )}
            leftIcon={
                resource.icon ? <resource.icon /> : <DefaultIcon />
            }
            onClick={onMenuClick}
            sidebarIsOpen={open}
        />
    }

    const NestedMenuWithResources = ({title, resourceNames, open}) => {
        const initialOpen = open || find(resourceNames, n => location.pathname === `/${n}`) !== undefined
        return <NestedMenu leftIcon={<DefaultIcon/>} title={title} initialOpen={initialOpen}>
            {resourceNames.map(name => resources.filter(r => r.name === name)[0]).map(resource => <MenuResource
                resource={resource}/>)}
        </NestedMenu>;
    }
    return (
        <div>
            <NestedMenuWithResources title={'Sales'} resourceNames={['sessions', 'tickets', 'customers', 'syndicates']} open={true}/>
            <NestedMenuWithResources title={'Agents'} resourceNames={['agents', 'clerks', 'loyaltyRegistrations']}/>
            <NestedMenuWithResources title={'Products'} resourceNames={['articles', 'articleStyles','categories', 'categoryArticles', 'markets']}/>
            <NestedMenuWithResources title={'Accounting'} resourceNames={['accountTransactions', 'payouts']}/>
            <NestedMenuWithResources title={'Game Suppliers'} resourceNames={['suppliers', 'walletTransactions', 'supplierRequests', 'supplierEvents']}/>
            <NestedMenuWithResources title={'Operators'} resourceNames={['operators', 'payments', 'paymentBatches', 'marketOperators', 'operatorRegistrations']}/>
            <NestedMenuWithResources title={'Security'} resourceNames={['clients']}/>
            {isXSmall && logout}
        </div>
    );
};

export default Menu;