import React from 'react';
import {List, Datagrid, TextField, ReferenceField, DateField, Filter, SearchInput, DateTimeInput} from 'react-admin';
import AmountField from "../fields/AmountField";
import {TicketReferenceField} from "./tickets";
import {OperatorReferenceField} from "./operators";
import {CustomerReferenceField} from "./customers";
import {SyndicateReferenceField} from "./syndicates";
import {SessionReferenceField} from "./sessions";
import {AgentReferenceField} from "./agents";

const WalletTransactionFilter = (props) => (
    <Filter {...props}>
            <SearchInput source="search" alwaysOn />
            <DateTimeInput source="createdAtFrom" />
            <DateTimeInput source="createdAtTo" />
    </Filter>
);

export const WalletTransactionDataGrid = props =>
    <Datagrid rowClick="show" {...props}>
        <TextField source="id" />
        <DateField source="createdAt" showTime />
        <TextField source="type" />
        <AmountField source="amount" />

        <TicketReferenceField source="ticketId"/>

        <TextField source="supplierTransactionId"/>
        <AgentReferenceField source="agentId"/>
        <TextField source="supplierId"/>
        <TextField source="supplierBindingId"/>
        <SessionReferenceField source="sessionId"/>
        <CustomerReferenceField source="customerId"/>
        <OperatorReferenceField source="operatorId"/>
        <SyndicateReferenceField source="syndicateId"/>
    </Datagrid>;

export const WalletTransactionList = props => (
    <List {...props} filters={<WalletTransactionFilter/>} sort={{ field: 'createdAt', order: 'DESC' }}>
        <WalletTransactionDataGrid/>
    </List>
);

export const WalletTransactionReferenceField = props =>
    <ReferenceField source="walletTransactionId" reference="walletTransactions" link="show" {...props} >
        <TextField source="type" />
    </ReferenceField>;
