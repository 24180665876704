import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    Filter, SearchInput, DateTimeInput
} from 'react-admin';
import {OperatorReferenceField} from "./operators";
import {CustomerReferenceField} from "./customers";

const OperatorRegistrationFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
        <DateTimeInput source="createdAtFrom" />
        <DateTimeInput source="createdAtTo" />
    </Filter>
);

export const OperatorRegistrationDataGrid = props => (
    <Datagrid {...props}>
        <DateField source="createdAt" showTime />
        <CustomerReferenceField source="customerId"/>
        <OperatorReferenceField source="operatorId"/>
        <TextField source={"operatorCustomerId"}/>
    </Datagrid>
);
export const OperatorRegistrationList = props => (
    <List {...props} filters={<OperatorRegistrationFilter/>} sort={{ field: 'createdAt', order: 'DESC' }}>
        <OperatorRegistrationDataGrid/>
    </List>
);
