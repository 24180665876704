import React from 'react';
import {
    Edit,
    List,
    Datagrid,
    TextField,
    DateField,
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    ReferenceField,
    TopToolbar, required, Filter, SearchInput, DateTimeInput,
} from 'react-admin';
import CreateRegistrationClientButton from "./CreateRegistrationClientButton";
import {MarketReferenceField, MarketReferenceInput} from "./markets";

const AgentEditActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <CreateRegistrationClientButton record={data} />
    </TopToolbar>
);

const AgentFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
        <DateTimeInput source="createdAtFrom" />
        <DateTimeInput source="createdAtTo" />
    </Filter>
);

export const AgentList = props => (
    <List {...props} filters={<AgentFilter/>} sort={{ field: 'createdAt', order: 'DESC' }}>
        <Datagrid rowClick={'edit'}>
            <TextField source="id" />
            <DateField source="createdAt" showTime />
            <TextField source="name" />
            <TextField source="address"/>
            <TextField source="phone"/>
            <TextField source="email"/>
            <TextField source="web"/>
            <MarketReferenceField source="marketId"/>
        </Datagrid>
    </List>
);

export const AgentCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name"/>
            <TextInput source="address"/>
            <TextInput source="phone"/>
            <TextInput source="email"/>
            <TextInput source="web"/>
            <MarketReferenceInput validate={[required()]} source="marketId"  addLabel={true} label={'Market'}/>
        </SimpleForm>
    </Create>
);

export const AgentEdit = props => (
    <Edit actions={<AgentEditActions/>} undoable={false} {...props}>
        <SimpleForm>
            <TextField source="id" />
            <TextInput source="name"/>
            <TextInput source="address"/>
            <TextInput source="phone"/>
            <TextInput source="email"/>
            <TextInput source="web"/>
            <MarketReferenceInput validate={[required()]} source="marketId"  addLabel={true} label={'Market'}/>
        </SimpleForm>
    </Edit>
);

export const AgentReferenceInput = props =>
    <ReferenceInput label={'Agent'} source="agentId" reference="agents" {...props} >
        <AutocompleteInput optionText="name" emptyValue={""}/>
    </ReferenceInput>;

export const AgentReferenceField = props =>
    <ReferenceField source="agentId" reference="agents" {...props} >
        <TextField source="name" />
    </ReferenceField>;
