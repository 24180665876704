import React from 'react';
import PropTypes from 'prop-types';
import Amount from "../components/Amount";
import get from 'lodash/get';

const AmountField = ({ source, record = {} }) => <Amount amount={get(record, source)}/>;

AmountField.propTypes = {
    addLabel: PropTypes.bool,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

AmountField.defaultProps = {
    addLabel: true
};

export default AmountField;