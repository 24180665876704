import React from 'react';
import {
    Edit,
    List,
    Datagrid,
    TextField,
    DateField,
    Create,
    SimpleForm,
    TextInput,
    BooleanInput,
    PasswordInput,
    required,
    ReferenceField, Filter, SearchInput, DateTimeInput
} from 'react-admin';
import {AgentReferenceField, AgentReferenceInput} from "./agents";

const ClerkFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
        <DateTimeInput source="createdAtFrom" />
        <DateTimeInput source="createdAtTo" />
    </Filter>
);

export const ClerkList = props => (
    <List {...props} filters={<ClerkFilter/>} sort={{ field: 'createdAt', order: 'DESC' }}>
        <Datagrid rowClick={'edit'}>
            <TextField source="id" />
            <DateField source="createdAt" showTime />
            <DateField source="lastTokenAt" showTime label={'Last Used'}/>
            <TextField source="name" />
            <AgentReferenceField label={'Agent'}/>
        </Datagrid>
    </List>
);

// TODO use prop enableGetChoices={({ q }) => q && q.length >= 2} to start fetching data when user written 2 signs
export const ClerkCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" validate={[required()]}/>
            <AgentReferenceInput validate={[required()]} perPage={100}/>
            <BooleanInput source="admin"/>
            <BooleanInput source="education"/>
            <PasswordInput source="password" validate={[required()]}/>
        </SimpleForm>
    </Create>
);

export const ClerkEdit = props => (
    <Edit undoable={false} {...props}>
        <SimpleForm>
            <TextField source="id" />
            <TextInput source="name"/>
            <AgentReferenceField addLabel={true} label={'Agent'}/>
            <BooleanInput source="admin"/>
            <BooleanInput source="education"/>
            <PasswordInput source="password" label={'New Password'}/>
        </SimpleForm>
    </Edit>
);

export const ClerkReferenceField = props =>
    <ReferenceField source="clerkId" reference="clerks" {...props} >
        <TextField source="name" />
    </ReferenceField>;

