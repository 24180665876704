import React from 'react';
import {
    Edit,
    List,
    Datagrid,
    TextField,
    DateField,
    Create,
    SimpleForm,
    TextInput,
    required,
    ReferenceInput, AutocompleteInput, ReferenceField,
    useReference, Filter, SearchInput, DateTimeInput
} from 'react-admin';
import {MarketReferenceField, MarketReferenceInput} from "./markets";
import AmountField from "../fields/AmountField";
import AmountInput from "../fields/AmountInput";
import {SupplierReferenceField, SupplierReferenceInput} from "./suppliers";

const ArticleFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
        <DateTimeInput source="createdAtFrom" />
        <DateTimeInput source="createdAtTo" />
    </Filter>
);

export const ArticleList = props => (
    <List {...props} filters={<ArticleFilter/>} sort={{ field: 'createdAt', order: 'DESC' }}>
        <Datagrid rowClick={'edit'}>
            <TextField source="id" />
            <DateField source="createdAt" showTime />
            <TextField source="name" />
            <MarketReferenceField source="marketId"/>
            <AmountField source="amount" />
            <SupplierReferenceField source="supplierId" />
        </Datagrid>
    </List>
);

export const ArticleCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" validate={[required()]}/>
            <AmountInput source="amount" />
            <MarketReferenceInput validate={[required()]} source="marketId" addLabel={true} label={'Market'}/>
        </SimpleForm>
    </Create>
);

const SupplierParamsInput = ({record, source, label}) => {
    const { referenceRecord: supplier } = useReference({id: record.supplierId, reference: 'suppliers'})

    if (!supplier) return null;

    switch(supplier.integrationType) {
        case 'KAMBI':
            return <TextInput source={`${source}.slipRef`} label={'Slip Reference'} fullWidth/>
        case 'THELOTTER':
            return <div>
                    <TextInput source={`${source}.draw_id`} label={'Draw ID'} fullWidth/>
                    <TextInput source={`${source}.is_multiply`} label={'Is Multiply'} fullWidth/>
                    <TextInput source={`${source}.line_count`} label={'Line Count'} fullWidth/>
                  </div>
        default:
            return null;
    }
}


export const ArticleEdit = props => (
    <Edit undoable={false} {...props}>
        <SimpleForm>
            <TextField source="id" />
            <TextInput source="name"/>
            <AmountInput source="amount" />
            <MarketReferenceField source="marketId" label={'Market'} addLabel={true}/>
            <SupplierReferenceInput addLabel={true} label={'Supplier'} allowEmpty/>
            <h2 style={{width: '100%'}}>Game Supplier Parameters</h2>
            <SupplierParamsInput source={"supplierParams"}/>
        </SimpleForm>
    </Edit>
);

export const ArticleReferenceInput = props =>
    <ReferenceInput label={'Article'} source="articleId" reference="articles" {...props} >
        <AutocompleteInput optionText="name" emptyValue={""} />
    </ReferenceInput>;

export const ArticleReferenceField = props =>
    <ReferenceField source="articleId" reference="articles" {...props} >
        <TextField source="name" />
    </ReferenceField>;
