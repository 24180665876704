import React from 'react';
import {
    Edit,
    List,
    Datagrid,
    TextField,
    DateField,
    Create,
    SimpleForm,
    TextInput,
    PasswordInput,
    required,
    Filter, SearchInput, DateTimeInput
} from 'react-admin';
import {AgentReferenceField, AgentReferenceInput} from "./agents";
import {OperatorReferenceField, OperatorReferenceInput} from "./operators";
import {SupplierReferenceField, SupplierReferenceInput} from "./suppliers";

const ClientFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
        <DateTimeInput source="createdAtFrom" />
        <DateTimeInput source="createdAtTo" />
    </Filter>
);

export const ClientList = props => (
    <List {...props} filters={<ClientFilter/>} sort={{ field: 'createdAt', order: 'DESC' }}>
        <Datagrid rowClick={'edit'}>
            <TextField source="id" />
            <DateField source="createdAt" showTime />
            <DateField source="lastTokenAt" showTime label={'Last Used'}/>
            <TextField source="name" />
            <AgentReferenceField label={'Agent'}/>
            <OperatorReferenceField label={'Operator'}/>
            <SupplierReferenceField label={'Supplier'}/>
        </Datagrid>
    </List>
);

export const ClientCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" validate={[required()]}/>
            <PasswordInput source="secret"/>
            <AgentReferenceInput addLabel={true} label={'Agent'} allowEmpty/>
            <OperatorReferenceInput addLabel={true} label={'Operator'} allowEmpty/>
            <SupplierReferenceInput addLabel={true} label={'Supplier'} allowEmpty/>
        </SimpleForm>
    </Create>
);

export const ClientEdit = props => (
    <Edit undoable={false} {...props}>
        <SimpleForm>
            <TextField source="id" />
            <TextInput source="name"/>
            <PasswordInput source="secret" label={'New Secret'}/>
            <AgentReferenceInput addLabel={true} label={'Agent'} allowEmpty/>
            <OperatorReferenceInput addLabel={true} label={'Operator'} allowEmpty/>
            <SupplierReferenceInput addLabel={true} label={'Supplier'} allowEmpty/>
        </SimpleForm>
    </Edit>
);
