import React from 'react';
import {
    Edit,
    List,
    Datagrid,
    TextField,
    DateField,
    Create,
    SimpleForm,
    TextInput,
    required,
    ReferenceInput, AutocompleteInput, ReferenceField, NumberField, NumberInput, FormDataConsumer,
    Labeled, Filter, SearchInput, DateTimeInput
} from 'react-admin';
import {MarketReferenceField} from "./markets";
import {OperatorReferenceField} from "./operators";
import AmountInput from "../fields/AmountInput";
import NullableInput from "../fields/NullableInput";
import AmountField from "../fields/AmountField";
import {CustomerReferenceField} from "./customers";
import {AgentReferenceField} from "./agents";

export const LoyaltyRegistrationDataGrid = props => (
    <Datagrid {...props}>
        <DateField source="createdAt" showTime />
        <CustomerReferenceField source="customerId"/>
        <AgentReferenceField source="agentId"/>
        <TextField source={"crmCustomerId"}/>
    </Datagrid>
);

const LoyaltyRegistrationFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
        <DateTimeInput source="createdAtFrom" />
        <DateTimeInput source="createdAtTo" />
    </Filter>
);

export const LoyaltyRegistrationList = props => (
    <List {...props} filters={<LoyaltyRegistrationFilter/>}>
        <LoyaltyRegistrationDataGrid/>
    </List>
);
