import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    Show,
    SimpleShowLayout,
    ReferenceField,
    TopToolbar,
    Tab, ReferenceManyField, Pagination, TabbedShowLayout, Filter, SearchInput, DateTimeInput
} from 'react-admin';
import AmountField from "../fields/AmountField";
import {OperatorReferenceField} from "./operators";
import {CustomerReferenceField} from "./customers";
import {PaymentReferenceManyField} from "./payments";
import {SupplierReferenceField} from "./suppliers";
import {AgentReferenceField} from "./agents";
import {ClerkReferenceField} from "./clerks";
import {ArticleReferenceField} from "./articles";
import {SessionReferenceField} from "./sessions";
import {WalletTransactionDataGrid} from "./walletTransactions";
import {AccountTransactionDataGrid} from "./accountTransactions";
import {SupplierEventDataGrid} from "./supplierevents";

const SupplierRequestFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
        <DateTimeInput source="createdAtFrom" />
        <DateTimeInput source="createdAtTo" />
    </Filter>
);

export const SupplierRequestDataGrid = props =>
    <Datagrid rowClick="show" {...props}>
        <TextField source="id" />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
        <TextField source="requestType"/>
        <TextField source="status" />
        <TextField source="supplierReference" />
        <TextField source="retries" />
        <AmountField source="amount" />
        <SupplierReferenceField source={"supplierId"} label={"Supplier"}/>
        <AgentReferenceField source={"agentId"} label={"Agent"}/>
    </Datagrid>;


export const SupplierRequestList = props => (
    <List {...props} filters={<SupplierRequestFilter/>} sort={{ field: 'createdAt', order: 'DESC' }}>
        <SupplierRequestDataGrid/>
    </List>
);

export const SupplierRequestShow = props => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="summary">
                <TextField source="id" />
                <DateField source="createdAt" showTime />
                <DateField source="updatedAt" showTime />
                <TextField source="requestType"/>
                <AmountField source="amount" />
                <SupplierReferenceField source={"supplierId"} label={"Supplier"} addLabel={true}/>
                <AgentReferenceField source={"agentId"} label={"Agent"} addLabel={true}/>
                <TextField source={"supplierReference"}/>
                <TextField source="retries" />
                <ClerkReferenceField source={"clerkId"} label={"Clerk"} addLabel={true}/>
                <CustomerReferenceField source={"customerId"} label={"Customer"} addLabel={true}/>
            </Tab>
            <Tab label="Events" path="events">
                <ReferenceManyField pagination={<Pagination />} sort={{ field: 'createdAt', order: 'DESC' }} reference="supplierEvents" target="supplierRequestId" addLabel={false}>
                    <SupplierEventDataGrid/>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export const SupplierRequestReferenceField = props =>
    <ReferenceField source="supplierRequestId" reference="supplierRequests" link="show" {...props}>
        <TextField source="id" />
    </ReferenceField>;
