import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    Show,
    TabbedShowLayout,
    SimpleShowLayout,
    Tab,
    ReferenceManyField,
    ReferenceField,
    Pagination,
    Filter,
    TextInput, SearchInput, DateTimeInput
} from 'react-admin';
import AmountField from "../fields/AmountField";
import {AgentReferenceField} from "./agents";
import {WalletTransactionDataGrid} from "./walletTransactions";
import {AccountTransactionDataGrid} from "./accountTransactions";
import {TicketDataGrid} from "./tickets";
import {PaymentDataGrid} from "./payments";
import {OperatorRegistrationDataGrid} from "./operatorregistrations";
import {LoyaltyRegistrationDataGrid} from "./loyaltyregistrations";

const CustomerFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search"/>
        <DateTimeInput source="createdAtFrom" />
        <DateTimeInput source="createdAtTo" />
        <TextInput label="Personal Number" source="pno" alwaysOn />
    </Filter>
);

export const CustomerList = props => (
    <List {...props} filters={<CustomerFilter/>} sort={{ field: 'createdAt', order: 'DESC' }}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <DateField source="createdAt" showTime />
            <DateField source="lastSessionAt" showTime />
        </Datagrid>
    </List>
);

export const CustomerShow = props =>
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="summary">
                <SimpleShowLayout>
                    <TextField source="id" />
                    <DateField source="createdAt" showTime />
                    <DateField source="lastSessionAt" showTime />
                </SimpleShowLayout>
                <ReferenceManyField pagination={<Pagination />} sort={{ field: 'createdAt', order: 'DESC' }} reference="sessions" target="customerId" label={'Sessions'}>
                    <Datagrid rowClick="show">
                        <TextField source="id" />
                        <DateField source="createdAt" showTime />
                        <AmountField source="purchaseAmount" />
                        <AmountField source="refundAmount" />
                        <AgentReferenceField source={"agentId"} label={'Agent'}/>
                        <AmountField source="amount" />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab label="Tickets" path="tickets">
                <ReferenceManyField pagination={<Pagination />} sort={{ field: 'createdAt', order: 'DESC' }} reference="tickets" target="customerId" addLabel={false}>
                    <TicketDataGrid/>
                </ReferenceManyField>
            </Tab>
            <Tab label="Wallet Transactions" path="walletTransactions">
                <ReferenceManyField pagination={<Pagination />} sort={{ field: 'createdAt', order: 'DESC' }} reference="walletTransactions" target="customerId" addLabel={false}>
                    <WalletTransactionDataGrid/>
                </ReferenceManyField>
            </Tab>
            <Tab label="Account Transactions" path="accountTransactions">
                <ReferenceManyField pagination={<Pagination />} sort={{ field: 'createdAt', order: 'DESC' }} reference="accountTransactions" target="customerId" addLabel={false}>
                    <AccountTransactionDataGrid/>
                </ReferenceManyField>
            </Tab>
            <Tab label="Payments" path="payments">
                <ReferenceManyField pagination={<Pagination />} sort={{ field: 'createdAt', order: 'DESC' }} reference="payments" target="customerId" addLabel={false}>
                    <PaymentDataGrid/>
                </ReferenceManyField>
            </Tab>
            <Tab label="Operator Registrations" path="operatorRegistrations">
                <ReferenceManyField pagination={<Pagination />} sort={{ field: 'createdAt', order: 'DESC' }} reference="operatorRegistrations" target="customerId" addLabel={false}>
                    <OperatorRegistrationDataGrid/>
                </ReferenceManyField>
            </Tab>
            <Tab label="Loyalty Registrations" path="loyaltyRegistrations">
                <ReferenceManyField pagination={<Pagination />} sort={{ field: 'createdAt', order: 'DESC' }} reference="loyaltyRegistrations" target="customerId" addLabel={false}>
                    <LoyaltyRegistrationDataGrid/>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>;

export const CustomerReferenceField = props =>
    <ReferenceField source="customerId" reference="customers" link="show" {...props} >
        <TextField source="id" />
    </ReferenceField>;