import React from "react";
import Switch from '@material-ui/core/Switch';
import { Field } from 'react-final-form';
import {FormDataConsumer} from "react-admin";

import get from 'lodash/get';
const NullableSwitch = ({nullableDefaultValue, input: {onChange, value}}) =>
    <div>
        <Switch checked={value !== null}
                onChange={event => onChange(event.target.checked ? nullableDefaultValue : null)}/>

    </div>

const NullableInput = ({component, nullableDefaultValue, ...props}) =>
    <div style={{display: 'flex', flexDirection: 'row'}}>
        <Field name={props.source} component={NullableSwitch} nullableDefaultValue={nullableDefaultValue} allowNull={true} defaultValue={null} format={v => v}/>
        <FormDataConsumer>
            {({ formData }) =>
                get(formData, props.source, null) !== null && React.createElement(component, props)
            }
        </FormDataConsumer>
    </div>;


NullableInput.defaultProps = {
    addLabel: true
}
export default NullableInput;