import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    Show,
    SimpleShowLayout,
    ReferenceField,
    ReferenceManyField,
    SingleFieldList,
    ChipField, Tab, Pagination, TabbedShowLayout, Filter, SearchInput, DateTimeInput
} from 'react-admin';
import AmountField from "../fields/AmountField";
import {OperatorReferenceField} from "./operators";
import {CustomerReferenceField} from "./customers";
import {PayoutReferenceField} from "./payouts";
import {PaymentDataGrid} from "./payments";

const PaymentBatchFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
        <DateTimeInput source="createdAtFrom" />
        <DateTimeInput source="createdAtTo" />
    </Filter>
);

export const PaymentBatchDataGrid = props =>
    <Datagrid rowClick="show" {...props}>
        <TextField source="id"/>
        <DateField source="createdAt" showTime/>
        <TextField source={"status"}/>
        <OperatorReferenceField source={"operatorId"} label={'Operator'}/>
    </Datagrid>;


export const PaymentBatchList = props => (
    <List {...props} filters={<PaymentBatchFilter/>} sort={{field: 'createdAt', order: 'DESC'}}>
        <PaymentBatchDataGrid/>
    </List>
);

export const PaymentBatchShow = props => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label={'Summary'}>
                <TextField source="id"/>
                <DateField source="createdAt" showTime/>
                <TextField source={"status"}/>
                <OperatorReferenceField source={"operatorId"} label={'Operator'}/>
            </Tab>
            <Tab label="Payments" path="payments">
                <ReferenceManyField pagination={<Pagination />} sort={{ field: 'createdAt', order: 'DESC' }} reference="payments" target="batchId" addLabel={false}>
                    <PaymentDataGrid/>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

const IdReferenceField = ({record}) =>
    <ChipField source={"id"} record={{id: `${record.id || ""} (${record.status})`}}/>;

export const PaymentBatchReferenceField = props =>
    <ReferenceField source="batchId" reference="paymentBatches" link="show" {...props}>
        <IdReferenceField/>
    </ReferenceField>;

const PaymentBatchFieldList = props =>
    <SingleFieldList {...props} basePath={"/paymentBatches"} linkType={"show"}>
        <IdReferenceField/>
    </SingleFieldList>;

export const PaymentBatchReferenceManyField = props =>
    <ReferenceManyField label="PaymentBatch" reference="paymentBatches" target="batchId" linkType={"show"} {...props}>
        <PaymentBatchFieldList/>
    </ReferenceManyField>;
