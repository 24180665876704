import {fetchUtils} from 'react-admin';

var apiUrl = 'http://localhost:8080/api';
if (window.location.hostname !== 'localhost') {
    apiUrl = 'https://' + window.location.hostname.replace("-bo.", "-bogateway.") + "/api"
} else {
    apiUrl = 'http://localhost:' + window.location.port + "/api";
}
export const fetchJson = fetchUtils.fetchJson;

export const fetchApiRaw = (url, options) => {
    return fetchJson(new URL(url, apiUrl + "/").href.replace(/\/$/, ""), options);
}

export default fetchJson(apiUrl).then(({headers, json}) => json);
