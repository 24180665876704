import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    DateField,
    Filter,
    SearchInput,
    DateTimeInput,
    Show,
    SimpleShowLayout
} from 'react-admin';
import AmountField from "../fields/AmountField";
import {AgentReferenceField} from "./agents";
import {ClerkReferenceField} from "./clerks";
import {SupplierReferenceField} from "./suppliers";
import {OperatorReferenceField} from "./operators";
import {TicketReferenceField} from "./tickets";
import {PayoutPaymentReferenceField, PayoutReferenceField} from "./payouts";
import {ArticleReferenceField} from "./articles";
import {MarketReferenceField} from "./markets";
import {CustomerReferenceField} from "./customers";
import {SessionReferenceField} from "./sessions";
import {WalletTransactionDataGrid} from "./walletTransactions";
import {PaymentReferenceManyField} from "./payments";

const AccountTransactionFilter = (props) => (
    <Filter {...props}>
            <SearchInput source="search" alwaysOn />
            <DateTimeInput source="createdAtFrom" />
            <DateTimeInput source="createdAtTo" />
    </Filter>
);

export const AccountTransactionDataGrid = props =>
    <Datagrid rowClick="show" {...props}>
        <TextField source="id" />
        <DateField source="createdAt" showTime />
        <TextField source="type" />
        <AmountField source="amount" />

        <TicketReferenceField source="ticketId"/>

        <AmountField source="sessionAmount" />
        <AgentReferenceField source={"agentId"} label={'Agent'}/>
        <ClerkReferenceField source={"clerkId"} label={'Clerk'}/>

        <SupplierReferenceField source={"supplierId"} label={'Supplier'}/>
        <OperatorReferenceField source={"operatorId"} label={'Operator'}/>
        <PayoutReferenceField source={"payoutId"} label={"Payout"} allowEmpty/>
        <PayoutPaymentReferenceField source={"payoutId"} label={"Payment"} allowEmpty/>
        <TextField source="customerId"/>
    </Datagrid>;

export const AccountTransactionShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <DateField source="createdAt" showTime />
            <TextField source="type" />
            <CustomerReferenceField source={"customerId"} label={'Customer'}/>
            <OperatorReferenceField source={"operatorId"} label={'Operator'}/>
            <SupplierReferenceField source={"supplierId"} label={'Supplier'}/>
            <SessionReferenceField source={"sessionId"} label={'Session'}/>
            <AgentReferenceField source={"agentId"} label={'Agent'}/>
            <TicketReferenceField source="ticketId"/>
            <PayoutReferenceField source={"payoutId"} label={"Payout"} allowEmpty/>
            <PayoutPaymentReferenceField source={"payoutId"} label={"Payment"} allowEmpty/>
            <TextField source="logId" />
            <ClerkReferenceField source={"clerkId"} label={'Clerk'}/>
            <AmountField source="amount" />
            <AmountField source="sessionAmount" />
        </SimpleShowLayout>
    </Show>
);

export const AccountTransactionList = props => (
    <List {...props} filters={<AccountTransactionFilter/>} sort={{ field: 'createdAt', order: 'DESC' }}>
        <AccountTransactionDataGrid/>
    </List>
);

export const AccountTransactionReferenceField = props =>
    <ReferenceField source="accountTransactionId" reference="accountTransactions" link="show" {...props} >
        <TextField source="type" />
    </ReferenceField>;
