// in src/comments/ApproveButton.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { showNotification as showNotificationAction } from 'react-admin';
import fetchApi from "../fetchApi";

class ApproveButton extends Component {
    state = {
        code: ''
    };

    handleCode(code) {
        this.setState((state, props) => ({code}));
    }
    handleClick = () => {
        const { record, showNotification } = this.props;

        fetchApi(record._links.registrationClients.href, {
            method: 'POST'
        }).then(({json: {accessToken}}) => {
            this.handleCode(accessToken);
        }).catch((e) => {
            showNotification('Error: comment not approved', 'warning')
        });
    }

    render() {
        const {code} = this.state;
        return <div>
                <Button variant={'contained'} color={'primary'} onClick={this.handleClick}>Create Terminal Registration Code</Button>
                {code && <div>New code is {code}</div>}
            </div>;
    }
}

ApproveButton.propTypes = {
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func,
};

export default connect(null, {
    showNotification: showNotificationAction,
})(ApproveButton);