import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    Show,
    TabbedShowLayout,
    SimpleShowLayout,
    Tab,
    ReferenceManyField,
    ReferenceField,
    Pagination, Filter, SearchInput, DateTimeInput
} from 'react-admin';
import AmountField from "../fields/AmountField";
import {AgentReferenceField} from "./agents";
import {ClerkReferenceField} from "./clerks";
import { withStyles } from '@material-ui/core/styles';
import {ArticleReferenceField} from "./articles";
import {WalletTransactionDataGrid, WalletTransactionReferenceField} from "./walletTransactions";
import {AccountTransactionDataGrid, AccountTransactionReferenceField} from "./accountTransactions";
import {CustomerReferenceField} from "./customers";
import {TicketDataGrid, TicketReferenceField} from "./tickets";

const SessionFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
        <DateTimeInput source="createdAtFrom" />
        <DateTimeInput source="createdAtTo" />
    </Filter>
);

export const SessionList = props => (
    <List {...props} filters={<SessionFilter/>} sort={{ field: 'createdAt', order: 'DESC' }}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <DateField source="createdAt" showTime />
            <AmountField source="amount" />
            <AmountField source="purchaseAmount" />
            <AmountField source="refundAmount" />
            <AgentReferenceField source={"agentId"} label={'Agent'}/>
            <CustomerReferenceField source={"customerId"}/>
        </Datagrid>
    </List>
);
const showStyles = {
    horizontal: {
        display: 'flex',
        flexDirection: 'row',
        flex: '1 0 auto',
        justifyContent: 'space-between'
    },
    right: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    amountCell: {
        textAlign: 'right'
    }
};

const SessionTransactionPanel = withStyles(showStyles)(({classes, ...props}) =>
    <SimpleShowLayout {...props} className={classes.horizontal}>
        <DateField source="createdAt" showTime />
        <ClerkReferenceField source={"clerkId"} label={'Clerk'} addLabel={'true'}/>
        <WalletTransactionReferenceField source={'walletTransactionId'} label={'Wallet Transaction'} addLabel={'true'}/>
        <AccountTransactionReferenceField source={'accountTransactionId'} label={'Account Transaction'} addLabel={'true'}/>
    </SimpleShowLayout>
);

export const SessionShow = withStyles(showStyles)(({classes, ...props}) => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="summary">
                <SimpleShowLayout className={classes.horizontal}>
                    <TextField label="Id" source="id" />
                    <DateField source="createdAt" showTime/>
                    <AmountField source="purchaseAmount" />
                    <AmountField source="refundAmount" />
                    <AgentReferenceField source={"agentId"} label={'Agent'} addLabel={'true'}/>
                    <CustomerReferenceField source={"customerId"} addLabel={'true'}/>
                </SimpleShowLayout>
                <SimpleShowLayout className={classes.horizontal}>
                    <DateField source="closedAt" showTime/>
                    <ClerkReferenceField source={"clerkId"} label={'Opened By'} addLabel={'true'}/>
                    <ClerkReferenceField source={"closeClerkId"} label={'Closed By'} addLabel={'true'}/>
                    <AmountField source="closeAmount" />
                </SimpleShowLayout>
                <ReferenceManyField pagination={<Pagination />} sort={{ field: 'logId', order: 'ASC' }} reference="sessionTransactions" target="sessionId" addLabel={false}>
                    <Datagrid expand={<SessionTransactionPanel/>}>
                        <TextField source="logId" label={'Log ID'}/>
                        <TextField source="type" />
                        <TicketReferenceField source="ticketId"/>
                        <AmountField source="amount" label={'Amount'} cellClassName={classes.amountCell} headerClassName={classes.amountCell}/>
                    </Datagrid>
                </ReferenceManyField>
                <SimpleShowLayout className={classes.right}>
                    <AmountField source="amount" label={'Total'}/>
                </SimpleShowLayout>
            </Tab>
            <Tab label="Tickets" path="tickets">
                <ReferenceManyField pagination={<Pagination />} sort={{ field: 'createdAt', order: 'DESC' }} reference="tickets" target="sessionId" addLabel={false}>
                    <TicketDataGrid/>
                </ReferenceManyField>
            </Tab>
            <Tab label="Wallet Transactions" path="walletTransactions">
                <ReferenceManyField pagination={<Pagination />} sort={{ field: 'createdAt', order: 'DESC' }} reference="walletTransactions" target="sessionId" addLabel={false}>
                    <WalletTransactionDataGrid/>
                </ReferenceManyField>
            </Tab>
            <Tab label="Account Transactions" path="accountTransactions">
                <ReferenceManyField pagination={<Pagination />} sort={{ field: 'createdAt', order: 'DESC' }} reference="accountTransactions" target="sessionId" addLabel={false}>
                    <AccountTransactionDataGrid/>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
));

export const SessionReferenceField = props =>
    <ReferenceField source="sessionId" reference="sessions" link="show" {...props}>
        <TextField source="id" />
    </ReferenceField>;