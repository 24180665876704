import React from 'react';
import {
    Edit,
    List,
    Datagrid,
    TextField,
    DateField,
    Create,
    SimpleForm,
    TextInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    required,
    AutocompleteInput,
    FormDataConsumer, Filter, SearchInput, DateTimeInput
} from 'react-admin';

const SupplierFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
        <DateTimeInput source="createdAtFrom" />
        <DateTimeInput source="createdAtTo" />
    </Filter>
);

export const SupplierList = props => (
    <List {...props} filters={<SupplierFilter/>} sort={{ field: 'createdAt', order: 'DESC' }}>
        <Datagrid rowClick={'edit'}>
            <TextField source="id" />
            <DateField source="createdAt" showTime />
            <TextField source="name" />
            <ReferenceField source="operatorId" reference="operators"><TextField source="name" /></ReferenceField>
        </Datagrid>
    </List>
);

export const SupplierCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="id" validate={[required()]}/>
            <TextInput source="name"/>
            <ReferenceInput source="operatorId" reference="operators" validate={[required()]}><SelectInput optionText="name" /></ReferenceInput>
        </SimpleForm>
    </Create>
);

const integrationTypeChoices = [
    {id: 'NONE', name: 'None'},
    {id: 'MOCK', name: 'Mock'},
    {id: 'KAMBI', name: 'Kambi'},
    {id: 'THELOTTER', name: 'The Lotter'},
    {id: 'SYN', name: 'Syndicate'},
];

const IntegrationParamsInput = ({integrationType, source, ...rest}) => {
    switch(integrationType) {
        case 'KAMBI':
                return <TextInput source={`${source}.url`} label={'Endpoint URL'} fullWidth/>
        case 'THELOTTER':
        return <div>
          <TextInput source={`${source}.base_host`} label={'Endpoint URL'} fullWidth/>
          <TextInput source={`${source}.auth_user`} label={'Auth User'} fullWidth/>
          <TextInput source={`${source}.auth_pass`} label={'Auth Password'} fullWidth/>
          </div>
        default: {
            return '';
        }
    }
}

export const SupplierEdit = props => (
    <Edit undoable={false} {...props}>
        <SimpleForm>
            <TextField source="id" />
            <TextInput source="name"/>
            <ReferenceInput source="operatorId" reference="operators" validate={[required()]}><SelectInput optionText="name" /></ReferenceInput>
            <SelectInput source={"integrationType"} label={'Integration Type'} choices={integrationTypeChoices}/>
            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    <IntegrationParamsInput source={'integrationParams'} integrationType={formData.integrationType} {...rest}/>
                }
            </FormDataConsumer>
        </SimpleForm>
    </Edit>
);

export const SupplierReferenceInput = props =>
    <ReferenceInput label={'Supplier'} source="supplierId" reference="suppliers" {...props} >
        <AutocompleteInput optionText="name" emptyValue={""} />
    </ReferenceInput>;

export const SupplierReferenceField = props =>
    <ReferenceField source="supplierId" reference="suppliers" {...props} >
        <TextField source="name" />
    </ReferenceField>;
