import {fetchApiRaw} from './api';
import {getAccessToken, updateAccessToken} from "./apiToken";

const fetchApi = async (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers();
    }

    options.headers.set('Authorization', `Bearer ${await getAccessToken()}`);

    return fetchApiRaw(url, options)
        .catch(async err => {
            if (err.status === 401) {
                await updateAccessToken()
                options.headers.set('Authorization', `Bearer ${await getAccessToken()}`);
                return fetchApiRaw(url, options);
            } else {
                return Promise.reject(err)
            }
        });
};

export default fetchApi;