import * as React from "react";

export const formatAmount = (amount) =>
    (amount === null || amount === undefined || amount.currency === "") ?
        "":
        new Intl.NumberFormat(undefined, { style: 'currency', currency: amount.currency }).format(amount.amount)
;

export default ({amount, style}) =>
    <span style={style}>{formatAmount(amount)}</span>;
