import firebasePromise from "./firebase";
import apiPromise, {fetchJson} from "./api";

function getCurrentUser(auth) {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            unsubscribe();
            resolve(user);
        }, reject);
    });
}

const fetchAccessToken = async () => {
    const firebase = await firebasePromise;
    const api = await apiPromise;
    const formData = new FormData();
    const currentUser = await getCurrentUser(firebase.auth());
    const idToken = await currentUser.getIdToken(false);

    formData.append("grant_type", "urn:ietf:params:oauth:grant-type:token-exchange");
    formData.append("subject_token", idToken);
    formData.append("subject_token_type", "urn:ietf:params:oauth:token-type:id_token");

    const tokenResponse =  await fetchJson(api._links.token.href, {
        method: 'POST',
        body: formData,
    });

    return tokenResponse.json.access_token;
};

export const updateAccessToken = async () => {
    localStorage.setItem("token", await fetchAccessToken())
};

export const getAccessToken = async () => {
    if (localStorage.getItem("token") === null) {
        await updateAccessToken();
    }

    return localStorage.getItem("token");
};

