// Import FirebaseAuth and firebase.
import React, {useEffect, useState} from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebasePromise from './firebase';
import { useLogin } from 'ra-core';

const MyLoginPage = () => {

    const [isSignedIn, setIsSignedIn] = useState(null);
    const [firebase, setFirebase] = useState(null);
    const login = useLogin();

    useEffect(() => {
        firebasePromise.then(fb => setFirebase(fb));
    });

    useEffect(() => {
        if (!firebase) return;

        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(
            (user) => {
                if (user) {
                    login({user})
                }
                setIsSignedIn(!!user)
            }
        );

        return () => {
            unregisterAuthObserver()
        };
    });

    if (!isSignedIn && firebase) {

        // Configure FirebaseUI.
        const uiConfig = {
            // Popup signin flow rather than redirect flow.
            signInFlow: 'popup',
            // We will display Google and Facebook as auth providers.
            signInOptions: [
                firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            ],
            callbacks: {
                // Avoid redirects after sign-in.
                signInSuccessWithAuthResult: () => false
            }
        };


        return (
            <div>
                <h1>Betmarket OAP Platform</h1>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
            </div>
        );
    }
    return null;
};

export default MyLoginPage;