import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    Show,
    ReferenceField,
    TopToolbar,
    Tab,
    ReferenceManyField,
    Pagination,
    TabbedShowLayout,
    Filter,
    SearchInput,
    DateTimeInput
} from 'react-admin';
import AmountField from "../fields/AmountField";
import {ArticleReferenceField} from "./articles";
import {SupplierReferenceField} from "./suppliers";
import {OperatorReferenceField} from "./operators";
import {SessionReferenceField} from "./sessions";
import {CustomerReferenceField} from "./customers";
import ClaimToPayoutButton from "./ClaimToPayoutButton";
import CancelAdminButton from "./CancelAdminButton";
import {WalletTransactionDataGrid} from "./walletTransactions";
import {AccountTransactionDataGrid} from "./accountTransactions";
import {SupplierRequestDataGrid} from "./supplierrequests";
import {SupplierEventDataGrid} from "./supplierevents";
import {MarketReferenceField} from "./markets";
import {AgentReferenceField} from "./agents";
import {ClerkReferenceField} from "./clerks";
import {SyndicateReferenceField, SyndicateReferenceInput} from "./syndicates";

export const TicketDataGrid = props =>
    <Datagrid rowClick="show" {...props}>
        <TextField source="id" />
        <TextField source="name" />
        <DateField source="createdAt" showTime />
        <ArticleReferenceField source="articleId" label={'Article'}/>
        <DateField source="resultAt" showTime />
        <TextField source="result" />
        <AmountField source="purchaseAmount" />
        <AmountField source="resultAmount" />
        <AmountField source="refundAmount" />
        <AmountField source="claimedAmount" />
        <MarketReferenceField source={"marketId"} label={'Market'}/>
        <SupplierReferenceField source={"supplierId"} label={'Supplier'}/>
        <OperatorReferenceField source={"operatorId"} label={'Operator'}/>
        <AgentReferenceField source={"agentId"} label={'Agent'}/>
        <ClerkReferenceField source={"clerkId"} label={'Clerk'}/>
        <CustomerReferenceField source={"customerId"} label={'Customer'} addLabel={true}/>
        <SyndicateReferenceField source={"syndicateId"} label={'Syndicate'} addLabel={true}/>
        <SessionReferenceField source={"sessionId"} label={'Session'}/>
        <TextField source="supplierReference"/>
    </Datagrid>;

const TicketFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
        <DateTimeInput source="createdAtFrom" />
        <DateTimeInput source="createdAtTo" />
    </Filter>
);

export const TicketList = props => (
    <List {...props} filters={<TicketFilter/>} sort={{ field: 'createdAt', order: 'DESC' }}>
        <TicketDataGrid/>
    </List>
);

const TicketShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        {data && <CancelAdminButton record={data} />}
        {data && <ClaimToPayoutButton record={data} />}
    </TopToolbar>
);

const SupplierRequests = ({record, ...props}) =>
    record && record.supplierId && record.supplierReference ?
        <ReferenceManyField {...props} pagination={<Pagination />} sort={{ field: 'createdAt', order: 'DESC' }} reference="supplierRequests"
                            filter={({supplierId:record.supplierId, supplierReference:record.supplierReference})} addLabel={false}>
            <SupplierRequestDataGrid/>
        </ReferenceManyField>
        :null;

const SupplierEvents = ({record, ...props}) =>
    record && record.supplierId && record.supplierReference ?
        <ReferenceManyField {...props} pagination={<Pagination />} sort={{ field: 'createdAt', order: 'DESC' }} reference="supplierEvents"
                            filter={({supplierId:record.supplierId, supplierReference:record.supplierReference})} addLabel={false}>
            <SupplierEventDataGrid/>
        </ReferenceManyField>
        :null;

export const TicketShow = props => (
    <Show {...props} actions={<TicketShowActions/>}>
        <TabbedShowLayout>
            <Tab label="summary">
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="description" />
                <DateField source="createdAt" showTime />
                <ArticleReferenceField source="articleId" label={'Article'} addLabel={true}/>
                <TextField source="result" />
                <AmountField source="purchaseAmount" />
                <AmountField source="resultAmount" />
                <AmountField source="refundAmount" />
                <AmountField source="claimedAmount" />
                <MarketReferenceField source={"marketId"} label={'Market'} addLabel={true}/>
                <SupplierReferenceField source={"supplierId"} label={'Supplier'} addLabel={true}/>
                <OperatorReferenceField source={"operatorId"} label={'Operator'} addLabel={true}/>
                <AgentReferenceField source={"agentId"} label={'Agent'} addLabel={true}/>
                <ClerkReferenceField source={"clerkId"} label={'Clerk'} addLabel={true}/>
                <CustomerReferenceField source={"customerId"} label={'Customer'} addLabel={true}/>
                <SessionReferenceField source={"sessionId"} label={'Session'} addLabel={true}/>
                <TextField source="supplierReference" />
            </Tab>
            <Tab label="Wallet Transactions" path="walletTransactions">
                <ReferenceManyField pagination={<Pagination />} sort={{ field: 'createdAt', order: 'DESC' }} reference="walletTransactions" target="ticketId" addLabel={false}>
                    <WalletTransactionDataGrid/>
                </ReferenceManyField>
            </Tab>
            <Tab label="Account Transactions" path="accountTransactions">
                <ReferenceManyField pagination={<Pagination />} sort={{ field: 'createdAt', order: 'DESC' }} reference="accountTransactions" target="ticketId" addLabel={false}>
                    <AccountTransactionDataGrid/>
                </ReferenceManyField>
            </Tab>
            <Tab label="Supplier Requests" path="supplierRequests">
                <SupplierRequests/>
            </Tab>
            <Tab label="Supplier Events" path="supplierEvents">
                <SupplierEvents/>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

const IdReferenceField = ({record}) =>
    <span>{`${record.name || ""} (${record.id})`}</span>;

export const TicketReferenceField = props =>
    <ReferenceField source="ticketId" reference="tickets" link="show" {...props}>
        <IdReferenceField/>
    </ReferenceField>;