import React from 'react';
import {
    Edit,
    List,
    Datagrid,
    TextField,
    DateField,
    Create,
    SimpleForm,
    TextInput,
    required,
    ReferenceInput, AutocompleteInput, ReferenceField, NumberField, NumberInput, Filter, SearchInput, DateTimeInput
} from 'react-admin';
import {MarketReferenceField, MarketReferenceInput} from "./markets";

const CategoryFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
        <DateTimeInput source="createdAtFrom" />
        <DateTimeInput source="createdAtTo" />
    </Filter>
);

export const CategoryList = props => (
    <List {...props} filters={<CategoryFilter/>} sort={{ field: 'createdAt', order: 'DESC' }}>
        <Datagrid rowClick={'edit'}>
            <TextField source="id" />
            <DateField source="createdAt" showTime />
            <TextField source="name" />
            <MarketReferenceField source="marketId"/>
            <NumberField source="rank" />
        </Datagrid>
    </List>
);

export const CategoryCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" validate={[required()]}/>
            <NumberInput source="rank" />
            <MarketReferenceInput validate={[required()]} source="marketId"  addLabel={true} label={'Market'}/>
        </SimpleForm>
    </Create>
);

export const CategoryEdit = props => (
    <Edit undoable={false} {...props}>
        <SimpleForm>
            <TextField source="id" />
            <TextInput source="name"/>
            <NumberInput source="rank" />
            <MarketReferenceField source="marketId"/>
        </SimpleForm>
    </Edit>
);

export const CategoryReferenceInput = props =>
    <ReferenceInput label={'Category'} source="categoryId" reference="categories" {...props} >
        <AutocompleteInput optionText="name" emptyValue={""} />
    </ReferenceInput>;

export const CategoryReferenceField = props =>
    <ReferenceField source="categoryId" reference="categories" {...props} >
        <TextField source="name" />
    </ReferenceField>;
