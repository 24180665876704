import React from 'react';
import {
    Edit,
    List,
    Datagrid,
    TextField,
    DateField,
    Create,
    SimpleForm,
    TextInput,
    NumberInput,
    SelectInput,
    required,
    ReferenceInput, AutocompleteInput, ReferenceField,
    TabbedForm, FormTab, ReferenceManyField, Pagination, Tab, Filter, SearchInput, DateTimeInput
} from 'react-admin';
import {WalletTransactionDataGrid} from "./walletTransactions";
import {MarketOperatorDataGrid} from "./marketoperators";

const OperatorFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
        <DateTimeInput source="createdAtFrom" />
        <DateTimeInput source="createdAtTo" />
    </Filter>
);

export const OperatorList = props => (
    <List {...props} filters={<OperatorFilter/>} sort={{ field: 'createdAt', order: 'DESC' }}>
        <Datagrid rowClick={'edit'}>
            <TextField source="id" />
            <DateField source="createdAt" showTime />
            <TextField source="name" />
        </Datagrid>
    </List>
);

export const OperatorCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="id" validate={[required()]} />
            <TextInput source="name"/>
        </SimpleForm>
    </Create>
);

const paymentModeChoices = [
    {id: 'None', name: 'None'},
    {id: 'Batch', name: 'Batch'},
];

const paymentBatchSettingsFormatChoices = [
    {id: 'CSV', name: 'CSV'},
];

const paymentBatchSettingsDestinationChoices = [
    {id: 'GCS', name: 'GCS'},
    {id: 'None', name: 'None'},
];

export const OperatorEdit = props => (
    <Edit undoable={false} {...props}>
        <TabbedForm>
            <FormTab label="general">
                <TextField source="id" />
                <TextInput source="name"/>
                <SelectInput source="paymentMode" choices={paymentModeChoices}/>
                <SelectInput source="paymentBatchSettings.format" choices={paymentBatchSettingsFormatChoices} label={'Payment Batch Format'}/>
                <SelectInput source="paymentBatchSettings.destination" choices={paymentBatchSettingsDestinationChoices} label={'Payment Batch Destination'}/>
                <NumberInput source="paymentBatchFrequency" label={'Payment Batch Frequency'}/>
            </FormTab>
            <FormTab label="Market Settings" path="marketSettings">
                <ReferenceManyField pagination={<Pagination />} reference="marketOperators" target="operatorId" addLabel={false}>
                    <MarketOperatorDataGrid/>
                </ReferenceManyField>
            </FormTab>
        </TabbedForm>
    </Edit>
);

export const OperatorReferenceInput = props =>
    <ReferenceInput label={'Operator'} source="operatorId" reference="operators" {...props} >
        <AutocompleteInput optionText="name" emptyValue={""} />
    </ReferenceInput>;

export const OperatorReferenceField = props =>
    <ReferenceField source="operatorId" reference="operators" {...props} >
        <TextField source="name" />
    </ReferenceField>;
