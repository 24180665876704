import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    Show,
    SimpleShowLayout,
    ReferenceField,
    TopToolbar,
    Filter, SearchInput, DateTimeInput
} from 'react-admin';
import AmountField from "../fields/AmountField";
import {OperatorReferenceField} from "./operators";
import {CustomerReferenceField} from "./customers";
import {PaymentReferenceManyField} from "./payments";

const PayoutFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
        <DateTimeInput source="createdAtFrom" />
        <DateTimeInput source="createdAtTo" />
    </Filter>
);
export const PayoutDataGrid = props =>
    <Datagrid rowClick="show" {...props}>
        <TextField source="id" />
        <DateField source="createdAt" showTime />
        <AmountField source="amount" />
        <OperatorReferenceField source={"operatorId"} label={'Operator'}/>
        <CustomerReferenceField source={"customerId"} label={'Customer'} addLabel={true}/>
        <PaymentReferenceManyField label="Payment"  target="payoutId"/>
    </Datagrid>;


export const PayoutList = props => (
    <List {...props} filters={<PayoutFilter/>} sort={{ field: 'createdAt', order: 'DESC' }}>
        <PayoutDataGrid/>
    </List>
);

export const PayoutShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <DateField source="createdAt" showTime />
            <AmountField source="amount" />
            <OperatorReferenceField source={"operatorId"} label={'Operator'} addLabel={true}/>
            <CustomerReferenceField source={"customerId"} label={'Customer'} addLabel={true}/>
            <PaymentReferenceManyField label="Payment"  target="payoutId" addLabel={true}/>
        </SimpleShowLayout>
    </Show>
);

export const PayoutReferenceField = props =>
    <ReferenceField source="payoutId" reference="payouts" link="show" {...props}>
        <TextField source="id" />
    </ReferenceField>;

export const PayoutPaymentReferenceField = props =>
    <ReferenceField source="payoutId" reference="payouts" link={false} {...props}>
        <PaymentReferenceManyField/>
    </ReferenceField>;