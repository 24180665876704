import React from 'react';
import {
    Edit,
    List,
    Datagrid,
    TextField,
    DateField,
    Create,
    SimpleForm,
    TextInput,
    required,
    ReferenceInput, SelectInput, ReferenceField, Filter, SearchInput, DateTimeInput
} from 'react-admin';

const MarketFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
        <DateTimeInput source="createdAtFrom" />
        <DateTimeInput source="createdAtTo" />
    </Filter>
);

export const MarketList = props => (
    <List {...props} filters={<MarketFilter/>} sort={{ field: 'createdAt', order: 'DESC' }}>
        <Datagrid rowClick={'edit'}>
            <TextField source="id" />
            <DateField source="createdAt" showTime />
            <TextField source="name" />
            <TextField source="currency" />
            <TextField source="country" />
        </Datagrid>
    </List>
);

export const MarketCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" validate={[required()]}/>
            <TextInput source="currency" validate={[required()]}/>
            <TextInput source="country" validate={[required()]}/>
        </SimpleForm>
    </Create>
);

export const MarketEdit = props => (
    <Edit undoable={false} {...props}>
        <SimpleForm>
            <TextField source="id" />
            <TextInput source="name"/>
            <TextInput source="currency"/>
            <TextInput source="country"/>
        </SimpleForm>
    </Edit>
);

export const MarketReferenceInput = props =>
    <ReferenceInput label={'Market'} source="marketId" reference="markets" {...props} >
        <SelectInput optionText="name" emptyValue={""} />
    </ReferenceInput>;

export const MarketReferenceField = props =>
    <ReferenceField source="marketId" reference="markets" {...props} >
        <TextField source="name"/>
    </ReferenceField>;
