import React from 'react';
import { Admin, Resource, ShowGuesser } from 'react-admin';
import dataProvider from "./dataProvider";
import MyLoginPage from "./MyLoginPage";
import authProvider from "./authProvider";
import {TicketList, TicketShow} from "./resources/tickets";
import {WalletTransactionList} from "./resources/walletTransactions";
import { createMuiTheme } from '@material-ui/core/styles';
import {AccountTransactionList, AccountTransactionShow} from "./resources/accountTransactions";
import {OperatorCreate, OperatorEdit, OperatorList} from "./resources/operators";
import {SupplierCreate, SupplierEdit, SupplierList} from "./resources/suppliers";
import {AgentCreate, AgentEdit, AgentList} from "./resources/agents";
import {ClerkCreate, ClerkEdit, ClerkList} from "./resources/clerks";
import {ClientCreate, ClientEdit, ClientList} from "./resources/clients";
import {MarketCreate, MarketEdit, MarketList} from "./resources/markets";
import {CategoryCreate, CategoryEdit, CategoryList} from "./resources/categories";
import {ArticleCreate, ArticleEdit, ArticleList} from "./resources/articles";
import {CategoryArticleCreate, CategoryArticleEdit, CategoryArticleList} from "./resources/categoryarticles";
import {SessionList, SessionShow} from "./resources/sessions";
import {CustomerList, CustomerShow} from "./resources/customers";
import {PayoutList, PayoutShow} from "./resources/payouts";
import {PaymentList, PaymentShow} from "./resources/payments";
import {PaymentBatchList, PaymentBatchShow} from "./resources/paymentbatches";
import MyLayout from "./layout";
import {SupplierEventList, SupplierEventShow} from "./resources/supplierevents";
import {SupplierRequestList, SupplierRequestShow} from "./resources/supplierrequests";
import {MarketOperatorEdit, MarketOperatorList} from "./resources/marketoperators";
import {OperatorRegistrationList} from "./resources/operatorregistrations";
import {LoyaltyRegistrationList} from "./resources/loyaltyregistrations";
import {ArticleStyleCreate,ArticleStyleEdit, ArticleStyleList} from './resources/articlestyles';
import {SyndicateList, SyndicateShow} from "./resources/syndicates";

const theme = createMuiTheme({
    sidebar: {
        width: 300, // The default value is 240
        closedWidth: 70, // The default value is 55
    },
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#333333',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: '#6dc7c3',
        },
    },
});

const App = () =>
    <Admin theme={theme} layout={MyLayout} loginPage={MyLoginPage} authProvider={authProvider} dataProvider={dataProvider}>
        <Resource name="sessions" list={SessionList} show={SessionShow}/>
        <Resource name="sessionTransactions" options={{label: 'Session Transactions'}}/>
        <Resource name="tickets" list={TicketList} show={TicketShow}/>
        <Resource name="syndicates" list={SyndicateList} show={SyndicateShow}/>
        <Resource name="customers" list={CustomerList} show={CustomerShow}/>
        <Resource name="walletTransactions" options={{label: 'Wallet Transactions'}} list={WalletTransactionList} show={ShowGuesser}/>
        <Resource name="accountTransactions" options={{label: 'Account Transactions'}} list={AccountTransactionList} show={AccountTransactionShow}/>
        <Resource name="payouts" list={PayoutList} show={PayoutShow}/>
        <Resource name="payments" list={PaymentList} show={PaymentShow}/>
        <Resource name="paymentBatches" options={{label: 'Payment Batches'}} list={PaymentBatchList} show={PaymentBatchShow}/>
        <Resource name="operators" list={OperatorList} create={OperatorCreate} edit={OperatorEdit}/>
        <Resource name="marketOperators" options={{label: 'Market Operators'}} list={MarketOperatorList}  edit={MarketOperatorEdit}/>
        <Resource name="suppliers" list={SupplierList} create={SupplierCreate} edit={SupplierEdit}/>
        <Resource name="supplierEvents" options={{label: 'Supplier Events'}} list={SupplierEventList} show={SupplierEventShow}/>
        <Resource name="supplierRequests" options={{label: 'Supplier Requests'}} list={SupplierRequestList} show={SupplierRequestShow}/>
        <Resource name="agents" list={AgentList} create={AgentCreate} edit={AgentEdit}/>
        <Resource name="clerks" list={ClerkList} create={ClerkCreate} edit={ClerkEdit}/>
        <Resource name="clients" list={ClientList} create={ClientCreate} edit={ClientEdit}/>
        <Resource name="markets" list={MarketList} create={MarketCreate} edit={MarketEdit}/>
        <Resource name="categories" list={CategoryList} create={CategoryCreate} edit={CategoryEdit}/>
        <Resource name="articles" list={ArticleList} create={ArticleCreate} edit={ArticleEdit}/>
        <Resource name="articleStyles" list={ArticleStyleList} create={ArticleStyleCreate} edit={ArticleStyleEdit}/>
        <Resource name="categoryArticles" list={CategoryArticleList} create={CategoryArticleCreate} edit={CategoryArticleEdit}/>
        <Resource name="operatorRegistrations" options={{label: 'Operator Registrations'}} list={OperatorRegistrationList}/>
        <Resource name="loyaltyRegistrations" options={{label: 'Loyalty Registrations'}} list={LoyaltyRegistrationList}/>
    </Admin>;

export default App;