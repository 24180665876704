import React from 'react';
import {
    Edit,
    List,
    Datagrid,
    TextField,
    DateField,
    Create,
    SimpleForm,
    TextInput,
    required,
    ReferenceInput, AutocompleteInput, ReferenceField, NumberField, NumberInput, Filter, SearchInput, DateTimeInput
} from 'react-admin';
import {MarketReferenceField, MarketReferenceInput} from "./markets";
import {AgentReferenceField} from "./agents";
import {CategoryReferenceField, CategoryReferenceInput} from "./categories";
import {ArticleReferenceField, ArticleReferenceInput} from "./articles";

const CategoryArticleFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
        <DateTimeInput source="createdAtFrom" />
        <DateTimeInput source="createdAtTo" />
    </Filter>
);
export const CategoryArticleList = props => (
    <List {...props} filters={<CategoryArticleFilter/>} sort={{ field: 'rank', order: 'DESC' }}>
        <Datagrid rowClick={'edit'}>
            <TextField source="id" />
            <DateField source="createdAt" showTime />
            <CategoryReferenceField source="categoryId"/>
            <ArticleReferenceField source="articleId"/>
            <NumberField source="rank" />
        </Datagrid>
    </List>
);

export const CategoryArticleCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <CategoryReferenceInput source="categoryId" validate={[required()]} addLabel={true} label={'Category'}/>
            <ArticleReferenceInput source="articleId" validate={[required()]} addLabel={true} label={'Article'}/>
            <NumberInput source="rank" />
        </SimpleForm>
    </Create>
);

export const CategoryArticleEdit = props => (
    <Edit undoable={false} {...props}>
        <SimpleForm>
            <CategoryReferenceField source="categoryId"/>
            <ArticleReferenceField source="articleId"/>
            <NumberInput source="rank" />
        </SimpleForm>
    </Edit>
);

