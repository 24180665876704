import api from './api';
import firebase from 'firebase/app';
import 'firebase/auth';

const configureFirebase = async () => {
    const apiResult = await api;

    firebase.initializeApp(apiResult.firebase);

    return firebase;

};

export default configureFirebase();
