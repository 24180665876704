import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    Show,
    SimpleShowLayout,
    ReferenceField,
    TopToolbar,
    Filter, SearchInput, DateTimeInput
} from 'react-admin';
import AmountField from "../fields/AmountField";
import {OperatorReferenceField} from "./operators";
import {CustomerReferenceField} from "./customers";
import {PaymentReferenceManyField} from "./payments";
import {SupplierReferenceField} from "./suppliers";
import {AgentReferenceField} from "./agents";
import {SupplierRequestReferenceField} from "./supplierrequests";
import {ClerkReferenceField} from "./clerks";

const SupplierEventFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
        <DateTimeInput source="createdAtFrom" />
        <DateTimeInput source="createdAtTo" />
    </Filter>
);

export const SupplierEventDataGrid = props =>
    <Datagrid rowClick="show" {...props}>
        <TextField source="id" />
        <SupplierRequestReferenceField source={"supplierRequestId"}/>
        <DateField source="createdAt" showTime />
        <TextField source="type"/>
        <AmountField source="amount" />
        <SupplierReferenceField source={"supplierId"} label={"Supplier"}/>
        <AgentReferenceField source={"agentId"} label={"Agent"}/>
    </Datagrid>;


export const SupplierEventList = props => (
    <List {...props} filters={<SupplierEventFilter/>} sort={{ field: 'createdAt', order: 'DESC' }}>
        <SupplierEventDataGrid/>
    </List>
);

export const SupplierEventShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <DateField source="createdAt" showTime />
            <TextField source="type"/>
            <AmountField source="amount" />
            <SupplierReferenceField source={"supplierId"} label={"Supplier"} addLabel={true}/>
            <SupplierRequestReferenceField source={"supplierRequestId"} addLabel={true}/>
            <AgentReferenceField source={"agentId"} label={"Agent"} addLabel={true}/>
            <TextField source={"supplierReference"}/>
            <ClerkReferenceField source={"clerkId"} addLabel={true}/>
            <CustomerReferenceField source={"customerId"} addLabel={true}/>
        </SimpleShowLayout>
    </Show>
);

export const SupplierEventReferenceField = props =>
    <ReferenceField source="supplierEventId" reference="supplierEvents" link="show" {...props}>
        <TextField source="id" />
    </ReferenceField>;

export const SupplierEventPaymentReferenceField = props =>
    <ReferenceField source="supplierEventId" reference="supplierEvents" link={false} {...props}>
        <PaymentReferenceManyField/>
    </ReferenceField>;