import React from 'react';
import {
    Edit,
    List,
    Datagrid,
    TextField,
    DateField,
    Create,
    SimpleForm,
    TextInput,
    required,
    ReferenceInput, AutocompleteInput, ReferenceField, NumberField, NumberInput, FormDataConsumer,
    Labeled, Filter, SearchInput, DateTimeInput
} from 'react-admin';
import {MarketReferenceField} from "./markets";
import {OperatorReferenceField} from "./operators";
import AmountInput from "../fields/AmountInput";
import NullableInput from "../fields/NullableInput";
import AmountField from "../fields/AmountField";

const MarketOperatorFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
        <DateTimeInput source="createdAtFrom" />
        <DateTimeInput source="createdAtTo" />
    </Filter>
);

export const MarketOperatorDataGrid = props => (
    <Datagrid {...props} rowClick={'edit'}>
        <MarketReferenceField source="marketId"/>
        <OperatorReferenceField source="operatorId"/>
        <AmountField source="claimSettings.claimToAgentMinAmount" label={'Min agent claim'} />
        <AmountField source="claimSettings.claimToAgentMaxAmount" label={'Max agent claim'}/>
        <NumberField source="claimSettings.claimToPayoutTimeout" label={'Payout timeout'}/>
    </Datagrid>
);
export const MarketOperatorList = props => (
    <List {...props} filters={<MarketOperatorFilter/>} sort={{ field: 'rank', order: 'DESC' }}>
        <MarketOperatorDataGrid/>
    </List>
);

const ClaimSettings = props => {
    if (props.record === undefined) {
        return null;
    }
    let defaultAmount = {amount: 0, currency: props.record.currency};
    return <div style={{display: 'flex', flexDirection: 'column'}}>
        <Labeled label={'Minimum amount that agents must be able to pay out in cash'}>
            <NullableInput {...props} component={AmountInput} source="claimSettings.claimToAgentMinAmount"
                           nullableDefaultValue={defaultAmount}/>
        </Labeled>
        <Labeled label={'Maximum amount that can be claimed at agent, over this amount is automatically paid to operator account'}>
            <NullableInput {...props} component={AmountInput} source="claimSettings.claimToAgentMaxAmount"
                           nullableDefaultValue={defaultAmount}/>
        </Labeled>
        <Labeled label={'Unclaimed tickets are paid out automatically to operator account after a certain time'}>
            <NullableInput {...props} component={NumberInput} source="claimSettings.claimToPayoutTimeout"
                           label={'Time in seconds'} nullableDefaultValue={86400}/>
        </Labeled>
    </div>;
}

export const MarketOperatorEdit = props => (
    <Edit undoable={false} {...props}>
        <SimpleForm>
            <MarketReferenceField source="marketId" addLabel={true}/>
            <OperatorReferenceField source="operatorId" addLabel={true}/>
            <ClaimSettings/>
        </SimpleForm>
    </Edit>
);

