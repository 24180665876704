import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import CurrencySelect from "./CurrencySelect";
import {
    FormControl,
    TextField,
    InputLabel,
    MenuItem
} from '@material-ui/core';

const AmountTextField = ({input: {onChange, value}}) =>
    <TextField value={value} onChange={onChange} style={{width: '4em', marginRight: '8px'}} type={'number'}/>


const AmountInput = (props) => {
    const {source} = props;

    return <div style={{display: 'flex', alignItems: 'baseline'}}>
        <Field name={`${source}.amount`} component={AmountTextField} initialValue={0}/>
        <Field name={`${source}.currency`} component={CurrencySelect} initialValue={'SEK'}/>
    </div>;
};

AmountInput.defaultProps = {
    addLabel: true
};

export default AmountInput;