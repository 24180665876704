import React from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';
import cc from 'currency-codes';

const CurrencySelect = ({input: {onChange, value}, label}) =>
    <FormControl>
        {label && <InputLabel>{label}</InputLabel>}
        <Select value={value} onChange={onChange}>
            {cc.codes().map(code =>
                <MenuItem key={code} value={code}>{code}</MenuItem>
            )}
        </Select>
    </FormControl>;

export default CurrencySelect;