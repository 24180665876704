import React from 'react';
import {
    Edit,
    List,
    Datagrid,
    TextField,
    DateField,
    SimpleForm,
    TextInput,
    ReferenceField,
    ReferenceInput,
    AutocompleteInput,
    Filter, Show, TabbedShowLayout, Tab, ReferenceManyField, Pagination
} from 'react-admin';
import AmountField from "../fields/AmountField";
import {OperatorReferenceField} from "./operators";
import {ArticleReferenceField} from "./articles";
import {MarketReferenceField} from "./markets";
import {SupplierReferenceField} from "./suppliers";
import {AgentReferenceField} from "./agents";
import {ClerkReferenceField} from "./clerks";
import {CustomerReferenceField} from "./customers";
import {SessionReferenceField} from "./sessions";
import {WalletTransactionDataGrid} from "./walletTransactions";
import {AccountTransactionDataGrid} from "./accountTransactions";
import {TicketDataGrid} from "./tickets";

const SyndicateFilter = (props) => (
    <Filter {...props}>
    </Filter>
);

export const SyndicateList = props => (
    <List {...props} filters={<SyndicateFilter/>} sort={{ field: 'createdAt', order: 'DESC' }}>
        <Datagrid rowClick={'show'}>
            <TextField source="id" />
            <DateField source="createdAt" showTime />
            <TextField source="name" />
            <TextField source="status" />
            <AmountField source="amount" />
            <AmountField source="shareAmount" />
            <TextField source={"totalShares"}/>
            <TextField source={"purchasedShares"}/>
            <AmountField source="resultAmount" />
            <TextField source={"ticketsOpen"}/>
            <TextField source={"ticketsWithResults"}/>
            <AgentReferenceField source={"agentId"} label={'Agent'}/>
            <OperatorReferenceField source="operatorId"/>
        </Datagrid>
    </List>
);

export const SyndicateShow = props => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="summary">
                <TextField source="id" />
                <TextField source="name" />
                <DateField source="createdAt" showTime />
                <AmountField source="amount" />
                <AmountField source="shareAmount" />
                <TextField source={"totalShares"}/>
                <TextField source={"purchasedShares"}/>
                <AmountField source="resultAmount" />
                <TextField source={"ticketsOpen"}/>
                <TextField source={"ticketsWithResults"}/>
                <AgentReferenceField source={"agentId"} label={'Agent'} addLabel={'true'}/>
                <OperatorReferenceField source="operatorId" addLabel={true}/>
            </Tab>
            <Tab label="Tickets" path="tickets">
                <ReferenceManyField pagination={<Pagination />} sort={{ field: 'createdAt', order: 'DESC' }} reference="tickets" target="syndicateId" addLabel={false}>
                    <TicketDataGrid/>
                </ReferenceManyField>
            </Tab>
            <Tab label="Shares" path="shares">
                <ReferenceManyField pagination={<Pagination />} sort={{ field: 'createdAt', order: 'DESC' }} reference="tickets" target="shareSyndicateId" addLabel={false}>
                    <TicketDataGrid/>
                </ReferenceManyField>
            </Tab>
            <Tab label="Wallet Transactions" path="walletTransactions">
                <ReferenceManyField pagination={<Pagination />} sort={{ field: 'createdAt', order: 'DESC' }} reference="walletTransactions" target="syndicateId" addLabel={false}>
                    <WalletTransactionDataGrid/>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export const SyndicateReferenceInput = props =>
    <ReferenceInput label={'Syndicate'} source="syndicateId" reference="syndicates" {...props} >
        <AutocompleteInput optionText="name" emptyValue={""} />
    </ReferenceInput>;

export const SyndicateReferenceField = props =>
    <ReferenceField source="syndicateId" reference="syndicates" link="show" {...props} >
        <TextField source="name" />
    </ReferenceField>;
