import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        color: theme.palette.text.secondary,
    },
    active: {
        color: theme.palette.text.primary,
    },
    icon: { minWidth: theme.spacing(5) },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

const NestedMenu = ({leftIcon, children, title, initialOpen}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(initialOpen);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <div>
            <MenuItem
                className={classes.root}
                activeClassName={classes.active}
                onClick={handleClick}
            >
                {leftIcon && (
                    <ListItemIcon className={classes.icon}>
                        {React.cloneElement(leftIcon, {
                            titleAccess: title,
                        })}
                    </ListItemIcon>
                )}
                <ListItemText primary={title} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </MenuItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <div className={classes.nested}>
                    {children}
                </div>
            </Collapse>
        </div>
    );
}

export default NestedMenu;