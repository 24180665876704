import React from 'react';
import {
    Edit,
    List,
    Datagrid,
    TextField,
    Create,
    SimpleForm,
    required, Filter, SearchInput, DateTimeInput, DateField, minLength, maxLength
} from 'react-admin';
import {ColorInput,ColorField} from 'react-admin-color-input';
import {ArticleReferenceField, ArticleReferenceInput} from "./articles";

/*
function validateColor(c, allValues) {
    
    if (c.length !== 7 || c[0] !== '#') {
        return "Color value must be exactly 7 characters, a hash (#) followed by 6 characters, 0-9 and a-f/A-F";
    } else {
        try {
            var color = "0x" + c.substring(1);
            parseInt(color);
            return;
        } catch (e) {
            return "Color value must be exactly 7 characters, a hash (#) followed by 6 characters, 0-9 and a-f/A-F";
        }
    }
};
*/
const validateColor = [required(), minLength(7)];
/*
const validateColor = (values) => {
    const errors = {};
    if (values.backgroundColor.length !== 7 || values.backgroundColor[0] !== '#') {
        errors.backgroundColor = 'Color value must be exactly 7 characters, a hash (#) followed by 6 characters, 0-9 and a-f/A-F';
    } else {
        try {
            let color = "0x" + values.backgroundColor.substring(1);
            parseInt(color);
            return;
        } catch (e) {
            errors.backgroundColor = 'Color value must be exactly 7 characters, a hash (#) followed by 6 characters, 0-9 and a-f/A-F';
        }
    }
    if (values.foregroundColor.length !== 7 || values.foregroundColor[0] !== '#') {
        errors.foregroundColor = 'Color value must be exactly 7 characters, a hash (#) followed by 6 characters, 0-9 and a-f/A-F';
    } else {
        try {
            let color = "0x" + values.foregroundColor.substring(1);
            parseInt(color);
            return;
        } catch (e) {
            errors.foregroundColor = 'Color value must be exactly 7 characters, a hash (#) followed by 6 characters, 0-9 and a-f/A-F';
        }
    }
    return errors
};
*/

const ArticleStyleFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
        <DateTimeInput source="createdAtFrom" />
        <DateTimeInput source="createdAtTo" />
    </Filter>
);

export const ArticleStyleList = props => (
    <List {...props} filters={<ArticleStyleFilter/>} sort={{ field: 'articleId', order: 'DESC' }}>
        <Datagrid rowClick={'edit'}>
            <ArticleReferenceField source="articleId"/>
            <DateField source="createdAt" showTime />
            <ColorField source="backgroundColor"/>
            <ColorField source="foregroundColor"/>
            <TextField source="logo"/>
            <TextField source="supplierLogo"/>
            <TextField source="operatorLogo"/>
        </Datagrid>
    </List>
);

export const ArticleStyleCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <ArticleReferenceInput source="articleId" validate={[required()]} addLabel={true} label={'Article'}/>
            <ColorInput source="backgroundColor" validate={[required()]} label={'Background Color'}/>
            <ColorInput source="foregroundColor" validate={[required()]} label={'Text Color'}/>
        </SimpleForm>
    </Create>
);

const required2 = () => (value) =>
    value
        ? undefined
        : 'myroot.validation.required';

export const ArticleStyleEdit = props => (
    <Edit undoable={false} {...props}>
        <SimpleForm>
            <ColorInput source="backgroundColor" validate={[required(), minLength(7)]} label={'Background Color'}/>
            <ColorInput source="foregroundColor" validate={required2()} label={'Text Color'}/>
            <TextField source="logo" label={'Logo'}/>
            <TextField source="supplierLogo" label={'SupplierLogo'}/>
            <TextField source="operatorLogo" label={'OperatorLogo'}/>
        </SimpleForm>
    </Edit>
);
