import firebase from 'firebase/app'
import 'firebase/auth'

const authProvider =  {
    login: async ({user }) => {
        const idToken = await user.getIdToken(false);

        localStorage.setItem("idtoken", idToken);
        localStorage.setItem("permissions", [])

    },
    logout: () => {
        firebase.auth().signOut();
        localStorage.removeItem('token');
        localStorage.removeItem('permissions');
        localStorage.removeItem('signedin');
        return Promise.resolve();
    },
    checkError: error => {
        return Promise.resolve();
    },
    checkAuth: () => {
        return localStorage.getItem('idtoken') ? Promise.resolve() : Promise.reject();
    },
    getPermissions: () => {
        const role = localStorage.getItem('permissions');
        return role ? Promise.resolve(role) : Promise.reject();
    }
};

export default authProvider;
